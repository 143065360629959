import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  queryInvoice(docNumber: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/queryInvoice/${docNumber}`);
  }

  createPayment(paymentData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/createPayment`, paymentData);
  }

  charge(chargeData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/charge`, chargeData);
  }

  getRate(data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/rate`, { params: data });
  }

  // getCompanyInfo(): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/companyInfo`);
  // }
}
