import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  invoice: any;
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCompanyInfo(merchantId: string, companyId: string): Observable<any> {
    const params = merchantId ? { merchantId } : {};
    params['companyId'] = companyId;
    return this.http.get<any>(`${this.apiUrl}/companyInfo`, {
      params: params,
    });
  }

  revokeToken(companyId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/revokeByCompanyId`, {
      companyId,
    });
  }

  getInvoiceByDocNumber(
    docNumber: string,
    merchantId: string,
    companyId: string
  ): Observable<any> {
  //   return of({
  //     "QueryResponse": {
  //         "Invoice": [
  //             {
  //                 "AllowIPNPayment": false,
  //                 "AllowOnlinePayment": false,
  //                 "AllowOnlineCreditCardPayment": false,
  //                 "AllowOnlineACHPayment": false,
  //                 "EInvoiceStatus": "Sent",
  //                 "domain": "QBO",
  //                 "sparse": false,
  //                 "Id": "3",
  //                 "SyncToken": "2",
  //                 "MetaData": {
  //                     "CreateTime": "2024-08-04T08:42:55-07:00",
  //                     "LastUpdatedTime": "2024-08-09T13:49:21-07:00"
  //                 },
  //                 "CustomField": [],
  //                 "DocNumber": "1002",
  //                 "TxnDate": "2024-08-04",
  //                 "CurrencyRef": {
  //                     "value": "UYU",
  //                     "name": "Uruguayan Peso"
  //                 },
  //                 "LinkedTxn": [],
  //                 "Line": [
  //                     {
  //                         "Id": "1",
  //                         "LineNum": 1,
  //                         "Description": "This is a description for test!",
  //                         "Amount": 1,
  //                         "DetailType": "SalesItemLineDetail",
  //                         "SalesItemLineDetail": {
  //                             "ItemRef": {
  //                                 "value": "2",
  //                                 "name": "My category:First Service"
  //                             },
  //                             "UnitPrice": 1,
  //                             "Qty": 1
  //                         }
  //                     },
  //                     {
  //                         "Id": "2",
  //                         "LineNum": 2,
  //                         "Description": "Otro service",
  //                         "Amount": 5,
  //                         "DetailType": "SalesItemLineDetail",
  //                         "SalesItemLineDetail": {
  //                             "ItemRef": {
  //                                 "value": "4",
  //                                 "name": "Second Service"
  //                             },
  //                             "UnitPrice": 5,
  //                             "Qty": 1
  //                         }
  //                     },
  //                     {
  //                         "Amount": 6,
  //                         "DetailType": "SubTotalLineDetail",
  //                         "SubTotalLineDetail": {}
  //                     }
  //                 ],
  //                 "CustomerRef": {
  //                     "value": "1",
  //                     "name": "Adriel Alfaro"
  //                 },
  //                 "BillAddr": {
  //                     "Id": "4",
  //                     "Line1": "Durazno 1027",
  //                     "City": "Barrio Sur",
  //                     "Country": "Uruguay",
  //                     "CountrySubDivisionCode": "Montevideo",
  //                     "PostalCode": "11100"
  //                 },
  //                 "ShipAddr": {
  //                     "Id": "4",
  //                     "Line1": "Durazno 1027",
  //                     "City": "Barrio Sur",
  //                     "Country": "Uruguay",
  //                     "CountrySubDivisionCode": "Montevideo",
  //                     "PostalCode": "11100"
  //                 },
  //                 "SalesTermRef": {
  //                     "value": "3"
  //                 },
  //                 "DueDate": "2024-09-03",
  //                 "GlobalTaxCalculation": "NotApplicable",
  //                 "TotalAmt": 6,
  //                 "PrintStatus": "NotSet",
  //                 "EmailStatus": "EmailSent",
  //                 "BillEmail": {
  //                     "Address": "adrielalfaro88@gmail.com"
  //                 },
  //                 "Balance": 6,
  //                 "DeliveryInfo": {
  //                     "DeliveryType": "Email",
  //                     "DeliveryTime": "2024-08-04T08:43:08-07:00"
  //                 }
  //             }
  //         ],
  //         "startPosition": 1,
  //         "maxResults": 1,
  //         "totalCount": 1
  //     },
  //     "time": "2024-08-09T13:49:33.938-07:00"
  // });
    return this.http.get(`${this.apiUrl}/queryInvoice/${docNumber}`, {
      params: {
        merchantId,
        companyId,
      },
    });
  }

  getInvoice(): any {
    return this.invoice;
  }

  setInvoice(invoice: any): void {
    this.invoice = invoice;
  }

  generatePaymentLinkInStrictly(charge: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.apiUrl}/payment-link`, charge)
        .subscribe(resolve, reject);
    });
  }

  getAuthUri() {
    return this.http.get<{ authUri: string }>(`${this.apiUrl}/authUri`);
  }
}
