// merchant.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import MerchantInterface from '../interfaces/merchant.interface';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  private apiUrl = environment.apiUrl;
  private currentMerchant: any = undefined;
  private merchantLogoSubject = new BehaviorSubject<any>(undefined);
  merchantLogo$ = this.merchantLogoSubject.asObservable();

  constructor(private http: HttpClient) {}

  getMerchants(): Observable<MerchantInterface[]> {
    return this.http.get<MerchantInterface[]>(`${this.apiUrl}/merchants`);
  }

  getMerchant(id: string): Observable<MerchantInterface> {
    return this.http.get<MerchantInterface>(`${this.apiUrl}/merchants/${id}`);
  }

  getMerchantByLink(id: string): Observable<MerchantInterface> {
    return this.http.get<MerchantInterface>(`${this.apiUrl}/merchants/by-link/${id}`);
  }

  addMerchant(merchant: MerchantInterface): Observable<MerchantInterface> {
    return this.http.post<MerchantInterface>(`${this.apiUrl}/merchants`, merchant);
  }

  updateMerchant(id: string, merchant: MerchantInterface): Observable<MerchantInterface> {
    return this.http.put<MerchantInterface>(`${this.apiUrl}/merchants/${id}`, merchant);
  }

  deleteMerchant(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/merchants/${id}`);
  }

  getCurrentMerchant(): any {
    return this.currentMerchant;
  }

  setCurrentMerchant(merchant: any): void {
    this.currentMerchant = merchant;
    this.fetchMerchantLogo();
  }

  setMerchantLogo(logo: any): void {
    this.merchantLogoSubject.next(logo);
  }

  getMerchantLogo(): Observable<any> {
    return this.merchantLogo$;
  }

  private fetchMerchantLogo(): void {
    const logo = this.currentMerchant?.strictly?.logoUrl;
    this.setMerchantLogo(logo);
  }
}
