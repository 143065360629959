<div class="backdrop" *ngIf="isLoading">
  <div class="spinner-container">
    <div
      class="spinner-grow"
      style="width: 3rem; height: 3rem"
      role="status"
    ></div>
    <!-- <span class="sr-only">{{ textShow }}</span> -->
  </div>
</div>
