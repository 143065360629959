<div class="container mt-4">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h1 class="h3">PAIERLY</h1>
    <button class="btn btn-primary" (click)="addMerchant()">Add Merchant</button>
  </div>
  <table class="table table-hover table-striped">
    <thead class="thead-dark">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">QB Company ID</th>
        <th scope="col">Payment Link</th>
        <th scope="col" class="text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let merchant of merchants">
        <td>{{ merchant.name }}</td>
        <td>{{ merchant.intuit.companyId }}</td>
        <td>{{ merchant.paymentLink }}</td>
        <td class="text-center">
          <button class="btn btn-outline-primary btn-sm mr-2" (click)="editMerchant(merchant.id)">
            <i class="fa fa-edit"></i> Edit
          </button>
          <button class="btn btn-danger btn-sm" (click)="deleteMerchant(merchant.id)">
            <i class="fa fa-trash"></i> Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>