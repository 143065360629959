import { Component, OnInit } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { SpinnerComponent } from './components/ui/spinner/spinner.component';
import { environment } from '../environments/environment';
import { MerchantService } from './services/merchant.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SpinnerComponent, RouterModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'Paierly';
  merchantLogo: string | undefined;
  
  constructor(private merchantService: MerchantService) {
    localStorage.setItem('apiUrl', environment.apiUrl);
  }

  ngOnInit(): void {
    this.merchantService.getMerchantLogo().subscribe(logo => {
      this.merchantLogo = logo;
    });
  }
}
