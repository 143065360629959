<div class="container">
  <header class="text-center my-5">
    <h1>Paierly</h1>
    <p class="lead">
      End User License Agreement (EULA) & Terms of Service (TOS)
    </p>
  </header>

  <div class="content-section">
    <h2 class="mb-4">Terms of Service (TOS)</h2>
    <h5>Acceptance of Terms</h5>
    <p class="mb-4">
      By using the Paierly application, you agree to be bound by these Terms of
      Service. If you do not agree with these terms, you should not use the
      application.
    </p>

    <h5>Description of Service</h5>
    <p class="mb-4">
      Paierly allows users to charge their clients through QuickBooks. We
      reserve the right to modify or discontinue the service at any time without
      prior notice.
    </p>

    <h5>Acceptable Use</h5>
    <p class="mb-4">
      You agree to use Paierly in a lawful and respectful manner. It is
      prohibited to use the application for illegal activities, abusive
      behavior, or any actions that infringe upon the rights of others.
    </p>

    <h5>Intellectual Property</h5>
    <p class="mb-4">
      All intellectual property rights related to Paierly, including but not
      limited to copyrights and trademarks, are owned by Paierly or its
      licensors.
    </p>

    <h5>Limitation of Liability</h5>
    <p class="mb-4">
      Paierly will not be liable for any direct, indirect, incidental, special,
      or consequential damages resulting from the use or inability to use the
      application.
    </p>

    <h5>Modifications to Terms</h5>
    <p class="mb-4">
      We reserve the right to modify these Terms of Service at any time. We will
      notify you of any material changes through the application or by other
      reasonable means.
    </p>

    <h5>Termination</h5>
    <p class="mb-4">
      We reserve the right to terminate or suspend your access to Paierly at any
      time, without prior notice, for any reason, including but not limited to
      violations of these Terms of Service.
    </p>

    <h5>Governing Law</h5>
    <p class="mb-4">
      These Terms of Service will be governed by and construed in accordance
      with the laws of the country or state where our company is located.
    </p>

    <h5>Contact</h5>
    <p class="mb-4">
      If you have any questions about these Terms of Service, you can contact us
      at <a href="mailto:intuit@solvisse.com">intuit&#64;solvisse.com</a>.
    </p>
  </div>

  <div class="content-section">
    <h2 class="mb-4">End User License Agreement (EULA)</h2>
    <h5>Grant of License</h5>
    <p class="mb-4">
      Paierly grants you a limited, non-exclusive, non-transferable license to
      use the application in accordance with these terms.
    </p>

    <h5>Restrictions</h5>
    <p class="mb-4">
      You may not (a) modify, copy, or distribute the application; (b) use the
      application for any illegal purpose; or (c) attempt to decompile, reverse
      engineer, or extract the source code of the application.
    </p>

    <h5>Updates</h5>
    <p class="mb-4">
      Paierly may provide updates, enhancements, or modifications to the
      application. Any updates will be considered part of the application and
      subject to these terms.
    </p>

    <h5>Termination</h5>
    <p class="mb-4">
      Paierly may terminate your license at any time if you violate these terms.
      Upon termination, you must stop using the application and delete all
      copies in your possession.
    </p>

    <h5>Limitation of Liability</h5>
    <p class="mb-4">
      Paierly will not be liable for any damages arising from the use or
      inability to use the application.
    </p>

    <h5>Governing Law</h5>
    <p class="mb-4">
      This EULA will be governed by and construed in accordance with the laws of
      the country or state where our company is located.
    </p>

    <h5>Contact</h5>
    <p class="mb-4">
      If you have any questions about this EULA, you can contact us at
      <a href="mailto:intuit@solvisse.com">intuit&#64;solvisse.com</a>.
    </p>
  </div>

  <div class="footer">
    <p>&copy; 2024 Paierly. All rights reserved.</p>
  </div>
</div>
