export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDg1pvCdbZLhpcMMw8B1yfy7RtAGXfSfKc",
    authDomain: "paierly-prod.firebaseapp.com",
    databaseURL: "https://paierly-prod-default-rtdb.firebaseio.com",
    projectId: "paierly-prod",
    storageBucket: "paierly-prod.appspot.com",
    messagingSenderId: "944635618852",
    appId: "1:944635618852:web:cec9aa17c4a60a068f5ab1",
    measurementId: "G-11TY8QX70T"
  },
  apiUrl: "https://us-central1-paierly-prod.cloudfunctions.net/api",
  globalUrl: "https://us-central1-paierly-prod.cloudfunctions.net"
};