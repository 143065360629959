<app-spinner [isLoading]="isLoadingSpinner"></app-spinner>
<div
  class="d-flex justify-content-center align-items-center bg-light min-vw-100"
  *ngIf="!isLoadingSpinner"
>
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem">
          <div class="card-body p-5 text-center">
            <h3 class="mb-4">{{ isSignUpMode ? "Sign up" : "Sign in" }}</h3>

            <form
              [formGroup]="loginForm"
              (ngSubmit)="isSignUpMode ? signUp() : login()"
            >
              <div data-mdb-input-init class="form-outline mb-4">
                <input
                  type="email"
                  id="typeEmailX-2"
                  class="form-control form-control-lg"
                  formControlName="email"
                />
                <label class="form-label" for="typeEmailX-2">Email</label>
              </div>

              <div data-mdb-input-init class="form-outline mb-4">
                <input
                  type="password"
                  id="typePasswordX-2"
                  class="form-control form-control-lg"
                  formControlName="password"
                />
                <label class="form-label" for="typePasswordX-2">Password</label>
              </div>

              <button
                data-mdb-button-init
                data-mdb-ripple-init
                class="btn btn-primary btn-lg btn-block"
                type="submit"
              >
                {{ isSignUpMode ? "Sign up" : "Login" }}
              </button>
            </form>

            <hr class="my-4" *ngIf="!isSignUpMode && showSignUp" />

            <button
              *ngIf="!isSignUpMode && showSignUp"
              data-mdb-button-init
              data-mdb-ripple-init
              class="btn btn-lg btn-block btn-primary"
              (click)="loginWithGoogle()"
            >
              <i class="fab fa-google me-2"></i> Sign in with Google
            </button>

            <button
              class="btn btn-link mt-3"
              (click)="toggleSignUpMode()"
              *ngIf="!isSignUpMode && showSignUp"
            >
              {{
                isSignUpMode
                  ? "Already have an account? Sign in"
                  : "Don't have an account? Sign up"
              }}
            </button>

            <div *ngIf="errorText" class="alert alert-danger mt-3">
              {{ errorText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
