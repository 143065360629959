// login.component.ts
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../ui/spinner/spinner.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  imports: [CommonModule, ReactiveFormsModule, SpinnerComponent],
  standalone: true,
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isSignUpMode: boolean = false;
  errorText: string = '';
  isLoadingSpinner: boolean = false;
  showSignUp: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      email: [''],
      password: [''],
    });
  }

  ngOnInit(): void {
    this.isLoadingSpinner = true;
    this.authService.isLogged().subscribe({
      next: (isLogged) => {
        if (isLogged) {
          this.redirectToCLients();
        } else {
          this.authService.getInitialConfig().subscribe({
            next: (config) => {
              this.showSignUp = config.showSignUp;
              this.isLoadingSpinner = false;
            },
            // error: () => {
            //     this.router.navigate(['/error'], {
            //         queryParams: {
            //           code: '010',
            //           message: 'Network error',
            //         },
            //       });
            // },
          });
        }
      },
    });
  }

  login(): void {
    const { email, password } = this.loginForm.value;
    this.authService.login(email, password).subscribe({
      next: () => this.redirectToCLients(),
      error: (error) => (this.errorText = 'Login failed: ' + error.message),
    });
  }

  signUp(): void {
    const { email, password } = this.loginForm.value;
    this.authService.signUp(email, password).subscribe({
      next: () => this.redirectToCLients(),
      error: (error) => (this.errorText = 'Sign up failed: ' + error.message),
    });
  }

  loginWithGoogle(): void {
    this.authService.loginWithGoogle().subscribe({
      next: () => this.redirectToCLients(),
      error: (error) =>
        (this.errorText = 'Google login failed: ' + error.message),
    });
  }

  toggleSignUpMode(): void {
    this.isSignUpMode = !this.isSignUpMode;
    this.errorText = '';
  }

  private redirectToCLients(): void {
    this.router.navigate(['/list-merchant']);
  }
}
