<app-spinner [isLoading]="isLoadingSpinner"></app-spinner>
<div
  class="d-flex flex-column justify-content-center align-items-center bg-light min-vw-100"
  *ngIf="!isLoadingSpinner"
>
  <div class="custom-card d-flex flex-column align-items-center">
    <div *ngIf="!isConnected">
      <h1>Welcome to Paierly!</h1>
      <p class="text-center mb-4">
        Before we get started, please connect to QuickBooks to manage your
        invoices and payments.
      </p>
      <a class="imgLink mb-4" href="#" (click)="connectToQuickBooks($event)">
        <img
          src="assets/img/C2QB_green_btn_med_default.png"
          alt="Connect to QuickBooks"
        />
      </a>
    </div>
    <div *ngIf="isConnected">
      <h3>Welcome {{name}}</h3>
      <p class="text-center mt-3">
        You are connected to QuickBooks. You can now check your invoices and generate your payment links.
      </p>
      <span
        (click)="disconnectFromQuickBooks()"
        class="span-disconnect mt-3"
      > Disconnect From QuickBooks </span>
    </div>
  </div>
</div>
