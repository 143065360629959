<div class="container">
  <form [formGroup]="merchantForm" (ngSubmit)="onSubmit()">
    <!-- Merchant Information Card -->
    <div class="card shadow-sm border-0 mb-2">
      <div class="card-body">
        <h5 class="card-title">Merchant Information</h5>
        <div class="mb-3">
          <label for="name" class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            id="name"
            formControlName="name"
            placeholder="Enter merchant name"
          />
        </div>
        <div class="mb-3">
          <label for="paymentLink" class="form-label">Payment Link</label>
          <input
            type="text"
            class="form-control"
            id="paymentLink"
            formControlName="paymentLink"
            placeholder="Enter payment link"
          />
        </div>
      </div>
    </div>

    <!-- Strictly Information Card -->
    <div class="card shadow-sm border-0 mb-2">
      <div class="card-body">
        <h5 class="card-title">Strictly Information</h5>
        <div class="mb-3">
          <label for="strictlyPublic" class="form-label">Strictly Public</label>
          <input
            type="text"
            class="form-control"
            id="strictlyPublic"
            formControlName="strictlyPublic"
            placeholder="Enter strictly public info"
          />
        </div>
        <div class="mb-3">
          <label for="strictlySecret" class="form-label">Strictly Secret</label>
          <input
            type="text"
            class="form-control"
            id="strictlySecret"
            formControlName="strictlySecret"
            placeholder="Enter strictly secret info"
          />
        </div>
        <div class="mb-3">
          <label for="strictlyKey" class="form-label">API Key</label>
          <input
            type="text"
            class="form-control"
            id="strictlyKey"
            formControlName="strictlyKey"
            placeholder="Enter tokenization key"
          />
        </div>
        <div class="mb-3">
          <label for="strictlyLogoUrl" class="form-label">Logo Url</label>
          <input
            type="text"
            class="form-control"
            id="strictlyLogoUrl"
            formControlName="strictlyLogoUrl"
            placeholder="Logo Url"
          />
        </div>
      </div>
    </div>

    <!-- QuickBook Information Card -->
    <div class="card shadow-sm border-0 mb-2">
      <div class="card-body">
        <h5 class="card-title">QuickBook Information</h5>
        <div class="mb-3">
          <label for="quickBookCompanyId" class="form-label">QuickBook Company Id</label>
          <input
            type="text"
            class="form-control"
            id="quickBookCompanyId"
            formControlName="quickBookCompanyId"
            placeholder="Enter QuickBook Company Id"
          />
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="d-flex justify-content-between mt-4">
      <button type="submit" class="btn btn-primary btn-lg"> 
        {{ isEdit ? "Update" : "Add" }} Merchant
      </button>
      <button type="button" class="btn btn-secondary btn-lg" (click)="cancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
