import { Component, NgZone, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceService } from '../../services/invoice.service';
import { SpinnerComponent } from '../ui/spinner/spinner.component';

@Component({
  selector: 'app-qb-sso',
  templateUrl: './qb-sso.component.html',
  styleUrl: './qb-sso.component.scss',
  imports: [CommonModule, SpinnerComponent],
  standalone: true,
})
export class QuickBookSSOComponent implements OnInit {
  name: string = '';
  isConnected: boolean = false;
  isLoadingSpinner: boolean = false;

  constructor(private invoiceService: InvoiceService, private ngZone: NgZone) {}

  ngOnInit(): void {
    const realmId = localStorage.getItem('realmId');
    const companyId = localStorage.getItem('companyId');
    if (realmId && companyId) {
      this.isLoadingSpinner = true;
      this.invoiceService.getCompanyInfo(companyId, realmId).subscribe({
        next: (companyInfo) => {
          if (companyInfo.QueryResponse.CompanyInfo) {
            this.name = companyInfo.QueryResponse.CompanyInfo[0].CompanyName;
            this.isConnected = true;
            this.isLoadingSpinner = false;
          }
        },
        error: (error) => {
          console.error('Error fetching company info', error);
          this.isLoadingSpinner = false;
        }
      });
    } else {
      window.addEventListener('message', (event) => {
        if (event.data.message === 'merchant-registered') {
          this.ngZone.run(() => {
            this.showWelcomeMessage(event.data.data);
          });
        }
      });
    }
  }

  connectToQuickBooks(event: Event) {
    event.preventDefault();
    this.invoiceService.getAuthUri().subscribe((response) => {
      const width = 600;
      const height = 600;
      const left = screen.width / 2 - width / 2;
      const top = screen.height / 2 - height / 2;
      window.open(
        response.authUri,
        'QuickBooksAuth',
        `width=${width},height=${height},top=${top},left=${left}`
      );
    });
  }

  showWelcomeMessage(data: any) {
    const { merchantName, realmId, companyId } = data;
    localStorage.setItem('realmId', realmId);
    localStorage.setItem('companyId', companyId);
    this.name = merchantName;
    this.isConnected = true;
  }

  disconnectFromQuickBooks() {
    this.isLoadingSpinner = true;
    const realmId = localStorage.getItem('realmId');
    this.invoiceService.revokeToken(realmId).subscribe({
      next: () => {
        localStorage.removeItem('realmId');
        localStorage.removeItem('companyId');
        this.isConnected = false;
        this.isLoadingSpinner = false;
      },
      error: (error) => {
        console.error('Error revoking token', error);
        this.isLoadingSpinner = false;
      },
    });
  }
}
