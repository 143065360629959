import { Routes } from '@angular/router';
import { GetInvoiceComponent } from './components/invoices/get-invoice/get-invoice.component';
import { ListMerchantComponent } from './components/merchants/list-merchant/list-merchant.component';
import { AddEditMerchantComponent } from './components/merchants/add-edit-merchant/add-edit-merchant.component';
import { QuickBookSSOComponent } from './components/qb-sso/qb-sso.component';
import { EULAComponent } from './components/EULA/EULA.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { PaymentLinkComponent } from './components/payment-link/payment-link.component';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: 'quickbooks', component: QuickBookSSOComponent },
  { path: 'end-user-license-agreement', component: EULAComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '', redirectTo: '/quickbooks', pathMatch: 'full' },
  { path: 'merchant/:paymentLink/:invoiceNumber', component: GetInvoiceComponent },
  { path: 'merchant/:paymentLink', component: GetInvoiceComponent },
  {
    path: 'get-invoice',
    component: GetInvoiceComponent,
  },
  {
    path: 'payment-link',
    component: PaymentLinkComponent,
  },
  { path: 'login', component: LoginComponent }, 
  { path: 'list-merchant', component: ListMerchantComponent, canActivate: [AuthGuard] },
  { path: 'add-merchant', component: AddEditMerchantComponent, canActivate: [AuthGuard] },
  { path: 'edit-merchant/:id', component: AddEditMerchantComponent, canActivate: [AuthGuard] }, 
  { path: '**', component: ErrorPageComponent }
];

export { routes };
