<main class="main" style="height: 100vh">
  <div class="content h-full">
    <div
      class="d-flex flex-column justify-content-center align-items-center bg-light min-vh-100"
    >
      <img
        *ngIf="merchantLogo"
        [src]="merchantLogo"
        alt="Merchant Logo"
        class="merchant-logo"
      />

      <router-outlet />
    </div>
  </div>
</main>
