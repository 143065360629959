<div class="container">
  <header class="text-center my-5">
    <h1>Paierly</h1>
    <p class="lead">Privacy Policy</p>
  </header>

  <div class="content-section">
    <h5>Information We Collect</h5>
    <p class="mb-4">
      We collect personal information such as your name, email address, and
      billing information when you use Paierly.
    </p>

    <h5>Use of Information</h5>
    <p class="mb-4">
      We use the information collected to provide and improve our service,
      process payments, and communicate with you.
    </p>

    <h5>Sharing Data</h5>
    <p class="mb-4">
      We do not share your personal information with third parties except as
      necessary to provide the service (e.g., QuickBooks) or as required by law.
    </p>

    <h5>Data Protection</h5>
    <p class="mb-4">
      We implement reasonable security measures to protect your personal
      information against unauthorized access, alteration, disclosure, or
      destruction.
    </p>

    <h5>Access and Control of Your Data</h5>
    <p class="mb-4">
      You may access, correct, or delete your personal information by contacting
      us at <a href="mailto:intuit@solvisse.com">intuit&#64;solvisse.com</a>.
    </p>

    <h5>Changes to the Privacy Policy</h5>
    <p class="mb-4">
      We reserve the right to modify this Privacy Policy at any time. We will
      notify you of any material changes through the application or by other
      reasonable means.
    </p>

    <h5>Contact</h5>
    <p class="mb-4">
      If you have any questions about this Privacy Policy, you can contact us at
      <a href="mailto:intuit@solvisse.com">intuit&#64;solvisse.com</a>.
    </p>
  </div>

  <div class="footer">
    <p>&copy; 2024 Paierly. All rights reserved.</p>
  </div>
</div>
