<app-spinner [isLoading]="isLoadingSpinner"></app-spinner>
<div
  class="d-flex justify-content-center align-items-center bg-light min-vw-100"
  *ngIf="!isLoadingSpinner"
>
  <div
    class="custom-card"
    [ngClass]="
      invoiceData === null
        ? 'col-12 col-md-3'
        : 'col-12 col-md-8 bg-white p-5 rounded shadow-sm'
    "
  >
    <ng-container *ngIf="invoiceData === null">
      <h2 class="h4 text-center mb-4">Invoice Lookup</h2>
      <div>
        <input
          id="invoiceNumber"
          type="text"
          placeholder="Enter Invoice Number"
          [(ngModel)]="invoiceNumber"
          [disabled]="isLoading"
          class="form-control"
          (keypress)="validateNumber($event)"
        />
        <button
          (click)="getInvoice()"
          [disabled]="isLoading"
          class="btn btn-primary mt-3 w-100 button-flex"
        >
          <span *ngIf="!isLoading">Get Invoice</span>
          <span *ngIf="isLoading" class="d-flex align-items-center">
            <span
              class="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Fetching invoice...
          </span>
        </button>
      </div>
      <div
        *ngIf="errorMessage"
        class="alert custom-alert-danger alert-danger mt-3 mb-0 text-center"
      >
        {{ errorMessage }}
      </div>
      <div
        *ngIf="invoiceData && !isPaid"
        class="alert alert-success mt-4 text-center"
      >
        <p>Total amount: {{ invoiceData }}</p>
      </div>
      <div *ngIf="isPaid" class="alert alert-success mt-4 text-center">
        <p>Invoice marked as paid</p>
      </div>
    </ng-container>
    <ng-container *ngIf="invoiceData !== null">
      <div>
        <div *ngIf="companyInfo" class="mb-4">
          <div class="company-info-container">
            <div class="company-info-text view-only-desktop">
              <h2 class="h5">{{ companyInfo.CompanyName }}</h2>
              <p>{{ companyInfo.CompanyAddr?.Line1 }}</p>
              <p>
                {{ companyInfo.CompanyAddr?.City }},
                {{ companyInfo.CompanyAddr?.CountrySubDivisionCode }}
                {{ companyInfo.CompanyAddr?.PostalCode }}
              </p>
            </div>
          </div>
          <div class="company-info-text view-only-mobile">
            <h2 class="h5">{{ companyInfo.CompanyName }}</h2>
            <p>{{ companyInfo.CompanyAddr?.Line1 }}</p>
            <p>
              {{ companyInfo.CompanyAddr?.City }},
              {{ companyInfo.CompanyAddr?.CountrySubDivisionCode }}
              {{ companyInfo.CompanyAddr?.PostalCode }}
            </p>
          </div>
        </div>
        <span class="invoice-text">INVOICE</span>
        <div class="d-flex justify-content-between mb-4">
          <div class="invoice-bill-to mb-4">
            <p>
              <strong>BILL TO</strong>
            </p>
            <p>{{ invoiceData.CustomerRef?.name }}</p>
            <p>{{ invoiceData.BillAddr?.Line1 }}</p>
            <p>
              {{ invoiceData.BillAddr?.City }},
              {{ invoiceData.BillAddr?.CountrySubDivisionCode }}
              {{ invoiceData.BillAddr?.PostalCode }}
            </p>
          </div>
          <div class="invoice-info">
            <div class="text-end labels">
              <p><strong>INVOICE #:</strong></p>
              <p>
                <strong>DATE:</strong>
              </p>
            </div>
            <div class="values">
              <p>{{ invoiceData.DocNumber }}</p>
              <p>
                {{ invoiceData.MetaData?.CreateTime | date }}
              </p>
            </div>
          </div>
        </div>
        <table class="table table-bordered mb-4">
          <thead class="table-light">
            <tr>
              <th class="text-start hide-mobile">#</th>
              <th class="text-start">Product/service</th>
              <th class="text-start hide-mobile">Description</th>
              <th class="text-start hide-mobile">Quantity</th>
              <th class="text-start hide-mobile">Rate</th>
              <th class="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let line of invoiceData.Line">
              <td class="text-start hide-mobile" *ngIf="line.Id">
                {{ line.LineNum }}
              </td>
              <td class="text-start" *ngIf="line.Id">
                {{ line.SalesItemLineDetail?.ItemRef.name }}
              </td>
              <td class="text-start hide-mobile" *ngIf="line.Id">
                {{ line.Description }}
              </td>
              <td class="text-start hide-mobile" *ngIf="line.Id">
                {{ line.SalesItemLineDetail?.Qty }}
              </td>
              <td class="text-start hide-mobile" *ngIf="line.Id">
                {{ line.SalesItemLineDetail?.UnitPrice }}
              </td>
              <td class="text-end" *ngIf="line.Id">
                {{ line.Amount | currency }}
              </td>
            </tr>
            <tr *ngIf="invoiceData.TotalAmt !== invoiceData.Balance">
              <td class="text-start hide-mobile">
              </td>
              <td class="text-start">
                Already paid
              </td>
              <td class="text-start hide-mobile">
              </td>
              <td class="text-start hide-mobile">
              </td>
              <td class="text-start hide-mobile">
              </td>
              <td class="text-end">
                {{ invoiceData.TotalAmt - invoiceData.Balance | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-end mb-4">
          <p class="h6">
            <strong>TOTAL: {{ invoiceData.Balance | currency }}</strong>
          </p>
        </div>
        <button
          (click)="goToPaymentLink()"
          [disabled]="isLoading"
          class="btn btn-primary w-100 mb-2 button-flex"
        >
          Pay this invoice
        </button>
        <button
          (click)="searchAnotherInvoice()"
          class="btn btn-outline-primary w-100 button-flex"
        >
          Search Another Invoice
        </button>
      </div>
    </ng-container>
  </div>
</div>
