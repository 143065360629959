import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MerchantService } from '../../../services/merchant.service';

@Component({
  selector: 'app-add-edit-merchant',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './add-edit-merchant.component.html',
  styleUrls: ['./add-edit-merchant.component.css']
})
export class AddEditMerchantComponent implements OnInit {
  merchantForm: FormGroup;
  isEdit: boolean = false;
  merchantId: string | null = null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private merchantService: MerchantService,
  ) {
    this.merchantForm = this.fb.group({
      name: [''],
      paymentLink: [''],
      strictlyPublic: [''],
      strictlySecret: [''],
      strictlyKey: [''],
      strictlyLogoUrl: [''],
      quickBookCompanyId: ['']
    });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
        this.merchantId = params.get('id');
        this.isEdit = !!this.merchantId;
  
        if (this.isEdit) {
          this.merchantService.getMerchant(this.merchantId!).subscribe(merchant => {
            console.log(merchant);
            this.merchantForm.patchValue({
              name: merchant.name,
              paymentLink: merchant.paymentLink,
              strictlyPublic: merchant.strictly ? merchant.strictly.user : '',
              strictlySecret: merchant.strictly ?  merchant.strictly.password : '',
              strictlyKey: merchant.strictly ? merchant.strictly.key : '',
              strictlyLogoUrl: merchant.strictly ? merchant.strictly.logoUrl : '',
              quickBookCompanyId: merchant.intuit.companyId
            });
          });
        }
      });
  }

  onSubmit() {
    const merchantData = {
      name: this.merchantForm.value.name,
      paymentLink: this.merchantForm.value.paymentLink,
      strictly: {
        user: this.merchantForm.value.strictlyPublic,
        password: this.merchantForm.value.strictlySecret,
        key: this.merchantForm.value.strictlyKey,
        logoUrl: this.merchantForm.value.strictlyLogoUrl
      },
      intuit: {
        companyId: this.merchantForm.value.quickBookCompanyId
      }
    };

    if (this.isEdit) {
        this.merchantService.updateMerchant(this.merchantId!, merchantData).subscribe(() => {
            this.router.navigate(['/list-merchant']);
          });
    } else {
        this.merchantService.addMerchant(merchantData).subscribe(() => {
            this.router.navigate(['/list-merchant']);
          });
    }
  }

  cancel() {
    this.router.navigate(['/list-merchant']);
  }
}
