import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InvoiceService } from '../../../services/invoice.service';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../services/api.services';
import { MerchantService } from '../../../services/merchant.service';
import { SpinnerComponent } from '../../ui/spinner/spinner.component';

@Component({
  selector: 'app-get-invoice',
  standalone: true,
  templateUrl: './get-invoice.component.html',
  imports: [FormsModule, CommonModule, SpinnerComponent],
  styleUrl: './get-invoice.component.scss',
})
export class GetInvoiceComponent implements OnInit {
  
  invoiceNumber: string = '';
  isLoadingSpinner: boolean = false;
  isLoading: boolean = false;
  isLoading2: boolean = false;
  errorMessage: string | null = null;
  invoiceData: any | null = null;
  isPaid: boolean = false;
  companyInfo: any = null;
  companyLogo: string = '';
  isRouteWithInvoice: boolean = false;

  paymentData = {
    TotalAmt: 0,
    CustomerRef: {
      value: '',
    },
    Line: [
      {
        Amount: 1.0,
        LinkedTxn: [],
      },
    ],
  };
  paymentLink: string | null = null;

  constructor(
    private _invoiceService: InvoiceService,
    private _merchantService: MerchantService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.isLoadingSpinner = true;
    this.paymentLink = this.route.snapshot.paramMap.get('paymentLink');
    this.invoiceNumber = this.route.snapshot.paramMap.get('invoiceNumber') || '';
    this.isRouteWithInvoice = !!this.invoiceNumber;
    this._merchantService.getMerchantByLink(this.paymentLink).subscribe({
      next: (response) => {
        this._merchantService.setCurrentMerchant(response);
        this._invoiceService
          .getCompanyInfo(response.id, response.intuit.companyId)
          .subscribe({
            next: (companyInfo) => {
              if (companyInfo.QueryResponse.CompanyInfo) {
                this.companyInfo = companyInfo.QueryResponse.CompanyInfo[0];
              }
              if(this.invoiceNumber) {
                this.getInvoice();
              } else {
                this.isLoadingSpinner = false;
              }
            },
            error: () => {
              this.router.navigate(['/error'], {
                queryParams: {
                  code: '002',
                  message:
                    'Oops, something went wrong. Please try again later.',
                },
              });
            },
          });
      },
      error: () => {
        this.router.navigate(['/error'], {
          queryParams: {
            code: '003',
            message: 'Oops, something went wrong. Please try again later.',
          },
        });
      },
    });
  }

  validateNumber(event: KeyboardEvent) {
    const charCode = event.charCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  getInvoice(): void {
    if (!this.invoiceNumber) {
      this.errorMessage = 'Please enter a valid invoice number.';
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;
    this.isPaid = false;
    const currentMerchant = this._merchantService.getCurrentMerchant();
    this.companyLogo = currentMerchant.strictly.logoUrl;

    this._invoiceService
      .getInvoiceByDocNumber(
        this.invoiceNumber,
        currentMerchant.id,
        currentMerchant.intuit.companyId
      )
      .subscribe(
        (data: any) => {
          this.isLoading = false;
          if (data.QueryResponse.Invoice) {
            this.invoiceNumber = '';
            this._invoiceService.setInvoice(data.QueryResponse.Invoice[0]);
            if (data.QueryResponse.Invoice[0].Balance > 0) {
              this.invoiceData = data.QueryResponse.Invoice[0];
            } else {
              this.errorMessage = 'The invoice has already been paid.';
              this.invoiceNumber = '';
            }
          } else {
            this.errorMessage = 'Invoice not found.';
            this.invoiceData = null;
            this.invoiceNumber = '';
          }
          this.isLoadingSpinner = false;
        },
        (error: any) => {
          this.isLoading = false;
          this.errorMessage = 'An error occurred while fetching the invoice.';
          this.isLoadingSpinner = false;
          console.error(error);
        }
      );
  }

  goToPaymentLink(): void {
    this.router.navigate(['/payment-link']);
  }

  searchAnotherInvoice(): void {
    this.invoiceData = null;
  }
}
