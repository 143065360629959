import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../../services/invoice.service';
import { MerchantService } from '../../services/merchant.service';
import { Router } from '@angular/router';
import { SpinnerComponent } from '../ui/spinner/spinner.component';

@Component({
  selector: 'app-payment-link',
  standalone: true,
  templateUrl: './payment-link.component.html',
  imports: [SpinnerComponent],
  styleUrl: './payment-link.component.scss',
})
export class PaymentLinkComponent implements OnInit {
  currentMerchant: any;
  invoice: any;
  isLoadingSpinner = true;

  constructor(
    private _invoiceService: InvoiceService,
    private _merchantService: MerchantService,
    private _router: Router
  ) {}
  ngOnInit(): void {
    const storedInvoice = localStorage.getItem('invoice');
    this.currentMerchant = this._merchantService.getCurrentMerchant();
    if (storedInvoice) {
      this.invoice = JSON.parse(storedInvoice);
      localStorage.removeItem('invoice');
    } else {
      this.invoice = this._invoiceService.getInvoice();
    }

    if (!this.currentMerchant) {
      //TODO: REDIRECT TO ERROR PAGE
      this._router.navigate(['/error']);
    }

    if(!this.invoice) {
      this._router.navigate([`/merchant/${this.currentMerchant.paymentLink}`]);
    }

    const chargeObject = {
      amount: this.invoice.Balance,
      invoiceNumber: this.invoice.DocNumber,
      invoiceId: this.invoice.Id,
      merchantName: this.currentMerchant.name,
      merchantId: this.currentMerchant.id,
      companyId: this.currentMerchant.intuit.companyId,
      customerRefValue: this.invoice.CustomerRef.value,
      customerRefName: this.invoice.CustomerRef.name,
    }

    this._invoiceService.generatePaymentLinkInStrictly(chargeObject).then((response) => {
      if (response.paymentLink) {
        window.location.href = response.paymentLink;
      }
    }).catch(() => {
      this._router.navigate(['/error'], {
        queryParams: {
          code: '005',
          message:
            'Oops, something went wrong. Please try again later.',
        },
      });
    });
  }
}
