// auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Auth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  User,
} from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.globalUrl;
  constructor(
    private auth: Auth,
    private router: Router,
    private http: HttpClient
  ) {}

  login(email: string, password: string): Observable<void> {
    return from(
      signInWithEmailAndPassword(this.auth, email, password).then(() => {
        this.router.navigate(['/list-merchant']);
      })
    );
  }

  loginWithGoogle(): Observable<void> {
    return from(
      signInWithPopup(this.auth, new GoogleAuthProvider()).then(() => {
        this.router.navigate(['/list-merchant']);
      })
    );
  }

  signUp(email: string, password: string): Observable<void> {
    return from(
      createUserWithEmailAndPassword(this.auth, email, password).then(() => {
        this.router.navigate(['/list-merchant']);
      })
    );
  }

  logout(): Observable<void> {
    return from(
      this.auth.signOut().then(() => {
        this.router.navigate(['/login']);
      })
    );
  }

  isLogged(): Observable<boolean> {
    return new Observable((observer) => {
      this.auth.onAuthStateChanged((user) => {
        observer.next(!!user);
        observer.complete();
      });
    });
  }

  isLoggedIn(): boolean {
    return !!this.auth.currentUser;
  }

  getCurrentUser(): Observable<User | null> {
    return from([this.auth.currentUser]);
  }

  getInitialConfig(): Observable<any> {
    return this.http.get<{ config: any }>(`${this.apiUrl}/getConfig`);
  }
}
