import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MerchantService } from '../../../services/merchant.service';
import MerchantInterface from '../../../interfaces/merchant.interface';

@Component({
  selector: 'app-list-merchant',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './list-merchant.component.html',
  styleUrls: ['./list-merchant.component.css'],
})
export class ListMerchantComponent implements OnInit {
  merchants: MerchantInterface[] = [];

  constructor(
    private merchantService: MerchantService,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.getMerchants();
  }

  getMerchants() {
    this.merchantService.getMerchants().subscribe((data) => {
      this.merchants = data;
    });
  }

  editMerchant(id: string) {
    this.router.navigate(['/edit-merchant', id]);
  }

  deleteMerchant(id: string) {
    if (confirm('Are you sure you want to delete this merchant?')) {
      this.merchantService.deleteMerchant(id).subscribe(() => {
        this.getMerchants();
      });
    }
  }

  addMerchant() {
    this.router.navigate(['/add-merchant']);
  }
}
